<template>
  <div class="text-container">
    <p>
      Diese gesamte Palette wird unter anderem von unserem Kooperationspartner
      <a href="https://www.hug-beratung.de/">
        H.U.G Betriebswirtschaftliche Beratungsgesellschaft mbH
      </a>
      begleitet. Für die Ermittlung des Kaufpreises werden drei
      Apothekenwertansätze angewandt:
    </p>
    <ol>
      <li>Ertragswert/Übergewinn</li>
      <li>Cashflow*Vervielfältiger</li>
      <li>Umsatzbetrachtung (ohne Hochpreisartikel)</li>
    </ol>
    <p>
      Für die Finanzierung verfügen wir über sehr gute Kontakte zur
      Hypo-Vereinsbank.
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten mit unserem Partner bei der Finanzierung Ihrer Apotheke.",
      },
    ],
  },
};
</script>